/* ubuntu-regular */

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 400;
    src:
            local('UbuntuRegular'),
            url('../../assets/fonts/ubuntu-v15-latin-regular.woff2') format('woff2');
}

/* ubuntu-medium */

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 500;
    src:
            local('UbuntuMedium'),
            url('../../assets/fonts/ubuntu-v15-latin-500.woff2') format('woff2');
}

/* ubuntu-bold */

@font-face {
    font-family: 'Ubuntu';
    font-style: normal;
    font-weight: 700;
    src:
            local('UbuntuBold'),
            url('../../assets/fonts/ubuntu-v15-latin-700.woff2') format('woff2');
}