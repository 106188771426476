@import 'assets/fonts/font.css';

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background: none;
  font-family: Ubuntu, serif !important;
}

.iconWrapper svg {
  height: 45px;
  width: 45px;
}

